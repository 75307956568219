@font-face {
  font-family: 'Goldplay';
  src: url($assets-path + "goldplay-medium.otf") format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Goldplay';
  src: url($assets-path + "goldplay-bold.otf") format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Merge';
  src: url($assets-path + "Philatype - Merge-Light.otf") format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Merge';
  src: url($assets-path + "Philatype - Merge.otf") format('opentype');
  font-weight: bold;
}

.cta-button.donate:hover, .button.donate:hover, button.donate:hover, .cta-button.green:hover {
  background-color: lighten($brand-green,5%);
}
.cta-button.pink:hover {
  background-color: darken($brand-pink,5%);
}

.mainCallToAction {
  .socialIcons {
    margin-right: 25px;
  }
  .cta-button.lottery {
    background-color: $dark-blue-from-other-leaflet!important; // to override button bg colour on mobile 
    &:hover {
      background-color: darken($dark-blue-from-other-leaflet,10%)!important;
    }
  }
}

.homeIntroWrapper {
  font-size: 1em;
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel {
  .donateBtn { // make matdch other buttons
    margin-top: 20px;
    font-size: 1em;
    padding: 0.875em 1.75em;
  }
  .donationAmount {
    border-radius: 3px;
  }
  .donationAmountFigure {
    font-family: $headings-font-family;
    color: purple;
  }
}
.homepage-quickgiving--copy {
  margin: 0;
}

.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
  line-height: 1.5;
}

// Homefeeds - smaller screens
@media (max-width: map-get($breakpoints, lg)) {
  .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
  .feedItem:last-of-type [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    padding: $spacer;
  }
  .feedItem:last-of-type,
  .feedItem:last-of-type:hover {
    box-shadow: 0 1px 0 #636c72;
  }
}

// Newsletter
.footerBox[class*="ewsletter"] input:focus {
  background-color: $white;
}

// FAQs
.listedFaq, .accordianHeader {
  outline: $border-width solid $border-colour;
}
.accordianHeader:hover { background-color: $faq-item-header-background-colour; }
.accordianHeader { margin-bottom: 0; }
.listedFaq { margin-bottom: 30px; }
.accordianBody.faqAnswer .contentBlock { padding-top: $spacer * 2; }

// Appeal in carousel

.carousel .totaliserGivingPanel {
  display: block;
  .selectDonationAmount > span:first-child,
  .formQuestion.donationAmount input,
  .formQuestion.donationFrequency {
    display: none;
  }
  .selectDonationAmount {
    display: flex;
  }
  .formQuestion.donationAmount {
 //   display: inline-flex;
    background-color: white;
    padding: 0;
    border-radius: 3px;
    margin-right: 8px;
    &.donationSelected {
      background-color: $brand-green;
    }
    .donationAmountFigure {
      font-size: 1.5em;
      padding: 0.5rem 1rem;
      line-height: 1.5;
      display: block;
      color: $grey-dark;
      font-family: $headings-font-family;
    }
    img,
    .donationAmountDescription {
      display: none;
    }
  }
  .formQuestion.donationAmount.donationAmountOther {
    display: none;
    width: auto;
    label {
      margin: 0;
    }
  }
}

.appealTotaliser,
.totaliserBG {
  border: 0;
}

.postComments #uploadImage .dz-message {
  max-width: 300px;
}

// appeals

.post .appealActionsWrapper {
  padding: 0;
  position: relative;
}
span.totaliserFigure.raisedAmount {
  color: $grey-dark;
}